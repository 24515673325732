import React, { useEffect, useState } from "react";
//import Chart from 'chart.js/auto';
import Card from 'react-bootstrap/Card';
import SideBar from "../../graficos/components/SideBar";
import Graphics from "../../../components/Grafico/Graphics"
import axios from 'axios';
import Sensor from "./components/Sensor";
import Carousel from 'react-bootstrap/Carousel';
import Api from "../../../services/Api";
import DadosSensor from "./components/DadosSensor";

var ids = []
var currentId = 1
var roots = []
var array = []

const List = (props) => {
  const { data, instrumentos } = props;
  const empresa = JSON.parse(localStorage.getItem('empresa'))
  const [param] = useState(35)
  const [cards, setCards] = useState()
  const [entrada, setEntrada] = useState()
  const [saida, setSaida] = useState()
  const [datasets, setDatasets] = useState()
  const [location, setLocation] = useState()
  const [numberPosition, setNumberPosition] = useState()
  const [sensor, setSensor] = useState(null)

  const [index, ] = useState(0);

  const addId = () => {
    let index = 1
    roots = instrumentos.map(i => {
      i["id"] = index
      index++
      return i
    })
  }

  const handleGraphics = (j) => {
    var instrument = instrumentos.filter(i => i.id === currentId)[0]
    setNumberPosition(instrument)
    if (!instrument) {
      return
    }
    axios.all([
      axios.get(`${process.env.REACT_APP_API_MONITORA}/measurements/byEquip/lastn/${instrumentos[j].equipamentos.entrada.ns}/${param}`),
      axios.get(`${process.env.REACT_APP_API_MONITORA}/measurements/byEquip/lastn/${instrumentos[j].equipamentos.saida.ns}/${param}`),
    ])
      .then(axios.spread((obj1, obj2) => {
        obj1.data != null ? setEntrada(obj1.data) : setEntrada([]);
        obj2.data != null ? setSaida(obj2.data) : setSaida([]);
      }));
  }

  const getSensor = async () => {
    if (empresa) {
      array = []
      try {
        const sensores = await Api.get(`/measurements/dryer/lastn/SHTM-00002/1`, /* { headers: { 'x-access-token': token } } */);
        var j = sensores.data[0].data
        if (j && j.length > 6) {
          for (let i = 0; i < 6; i++) {
            var element = j[i];
            array.push(element)
          }
          j = array
        } else {
          setSensor(j)
        }
      } catch (error) {
        console.log("Ocorreu um erro ao buscar a empresa");
      }
    }
  }

  const showValues = () => {
    setCards(instrumentos.filter(i => i.id === currentId)[0])
    let x = [...instrumentos]
    let test = x.findIndex(x => x.id === currentId);
    let x1 = x.slice(0, test)
    let x2 = x.slice(test, x.length)
    setDatasets(x2.concat(x1))
  }

  useEffect(() => {
    addId()
    setLocation(data.location)
    handleGraphics(currentId - 1)
    showValues()
    getSensor()

    const intervalId = setInterval(() => {
      let index = ids.indexOf(currentId);
      if (index >= 0 && index < ids.length - 1) {
        currentId = ids[index + 1]
      }
      else {
        currentId = ids[0]
      }
      handleGraphics(currentId - 1)
      showValues();
      getSensor()
    }, 60000)
    return () => clearInterval(intervalId);
  }, [instrumentos])

  useEffect(() => {
    if (!instrumentos) {
      return
    }
    const _ids = instrumentos.map(i => i.id).sort()
    ids = _ids
    if (ids.length > 0) {
      currentId = ids[0]
    }
  }, [])

  return (
    <div>
      <div className="mb-2 col-sm-12" style={{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "stretch",

      }}>

        <div style={{
          "border": "solid 1px grey",
          "boxShadow": "0px 0px 5px 3px grey",
          "background": "rgb(255 255 255 / 80%)"
        }} className="mt-1 col-sm-3 rounded">
          <Carousel activeIndex={index} /* onSelect={handleSelect} */>
            {datasets && numberPosition &&
              datasets.map((item, i) => {
                return (
                  <Carousel.Item style={{ "marginBottom": "0px", "marginTop": "0px" }} key={item.id} interval={60000}>
                    <Card style={{ "background": "transparent", "margin": "0px" }}>
                      <h6 className='card-h6-barchart'>{item.name.toUpperCase()} - {item.curva ? item.curva.toUpperCase() : 'Curva não selecionada'.toUpperCase()}</h6>
                      <SideBar sideBar={item} />
                    </Card>
                  </Carousel.Item>
                )
              })}
          </Carousel>
        </div>

        <div style={{
          "marginLeft": "10px",
          "marginBottom": "0px"
        }} className="mt-1 col-sm-9">
          {sensor ?
            <DadosSensor data={sensor} />
            : <div> Sem dados. </div>
          }
        </div>
      </div>

      <div style={{ "display": "flex", "justifyContent": "space-between" }}>
        <div style={{ "marginRight": "10px" }} className="mt-1 mb-1 col-sm-9">
          {entrada && cards && location &&
            <Graphics data={entrada} equipamento={location} cards={cards.equipamentos.entrada} name={cards.name} curva={cards.curva} position="Equipamento de entrada" />
          }
          {saida && cards && location &&
            <Graphics data={saida} equipamento={location} cards={cards.equipamentos.saida} name={cards.name} curva={cards.curva} position="Equipamento de saída" />
          }
        </div>

        <div className="mt-1 mb-1 col-sm-3">
          {sensor &&
            <Sensor data={sensor} />
          }
        </div>

      </div>

    </div>
  )
}
export default List
