import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from "react";
import { Container, Accordion, Button, Col, Row } from "react-bootstrap";
import { CardMedidas } from './components/CardMedidas';
import SearchBar from '../../components/SearchBar/SearchBar';
import Api from '../../services/Api';

const Medidas = () => {
  const empresas = JSON.parse(localStorage.getItem('empresas'))
  const empresasFilter = empresas;

  const [company, setCompany] = useState(empresas)

  function ordenarPorNome(lista) {
    return lista.sort((a, b) => {
      const nomeA = a.name.toUpperCase();
      const nomeB = b.name.toUpperCase();
      if (nomeA < nomeB) {
        return -1;
      }
      if (nomeA > nomeB) {
        return 1;
      }
      return 0;
    });
  }

  ordenarPorNome(empresas);

  const handleSearchData = (value) => {
    var result = [...empresasFilter]
    let items = result && result.filter(equip =>
      (equip.name && equip.name.toUpperCase().includes(value.toUpperCase()))
    )
    setCompany(items.sort());
  };

  const handleSair = () => {
    const respostaConfirm = window.confirm('Tem certeza que deseja continuar?');
    if (respostaConfirm) {
      localStorage.clear();
      window.location.href = "/"
    }
  }

  useEffect(() => {
    const handleData = async () => {
      try {
        const data = await Api.get(`/empresas/listempresas`, { "headers": { 'x-access-token': process.env.REACT_APP_API_KEY } })
        if (data.status === 200) {
          localStorage.setItem('empresas', JSON.stringify(data.data))
        }
      } catch (err) {
        console.log('Erro ao carregar.')
      }
    }
    handleData()
  }, [])

  return (
    <Container className="mt-2 mb-2">
      <Row className="mb-2">
        <Col>
          <SearchBar onSearch={handleSearchData} />
        </Col>
      </Row>
      <Accordion className='mt-2 mb-2'>
        {company && company.map((item) => {
          return (
            <Accordion.Item key={item._id} eventKey={item._id}>
              <Accordion.Header>{item.name}</Accordion.Header>
              <Accordion.Body className='p-0 m-1'>
                <CardMedidas data={item} />
              </Accordion.Body>
            </Accordion.Item>
          )
        })}
      </Accordion>
      <Button className='col-sm-2' onClick={() => { handleSair() }} variant="outline-danger" size="sm">Sair</Button>
    </Container>
  )
}
export default Medidas;