import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Card } from "react-bootstrap";
import SideBar from "../../graficos/components/SideBar";
import Api from "../../../services/Api";

export const CardMedidas = (props) => {
    const { data } = props;

    const objectStyle = {
        border: "solid 1px black",
        backgroundColor: "rgb(220, 231, 233, 0.8)",
        padding: "5px",
        color: "black",
        boxShadow: "0px 0px 10px 5px grey",
        marginBottom: "15px",
    };

    const [dados, setDados] = useState([]);

    const getEquipamentos = async () => {
        try {
            var equip = await Api.get(`/instruments/company/${data._id}`, { "headers": { 'x-access-token': process.env.REACT_APP_API_KEY } });
            if (equip.status === 200) {
                setDados(equip.data);
            } else {
                setDados([])
            }
        } catch (error) {
            console.log("Ocorreu um erro ao buscar a empresa");
            return
        }
    }

    useEffect(() => {
        getEquipamentos()
    }, [data])

    return (
        <>
            {dados && dados.map((item) => {
                return (
                    <Card key={item._id} className="card-grafico" style={objectStyle} >
                        <h6 className='card-h6-barchart'>{item.name.toUpperCase()} - {item.curva.toUpperCase()}</h6>
                        <SideBar sideBar={item} />
                    </Card>
                )
            })}
        </>
    )
}