import React from "react"
import { Col, Row } from 'react-bootstrap'
import dateFormat from "dateformat"

const Card = (props) => {
  const { data, sideBar, equip, status, position } = props;

  return (
    <Row>
      <div style={{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "marginTop": "0px",
        "marginBottom": "0px"
      }}>
        <h6 style={{ "fontSize": "12px", "marginBottom": "3px", "marginTop": "3px" }}>Atualização de <b>{position}</b> <br /> {data && data.date && dateFormat(data.date, "UTC:dd/mm/yyyy' 'HH:MM:ss")}</h6>
        <div style={{ "display": "flex" }}>
          <p style={{ "fontSize": "11px", "marginBottom": "0px" }}>{equip ? equip : "---"} </p>
          {status === "online" ?
            <p style={{
              "fontSize": "10px",
              "marginBottom": "0px",
              "width": '32px',
              "background": '#539a73',
              "color": '#ffffff',
              'paddingTop': '1px',
              'paddingBottom': '1px',
              'borderRadius': '5px',
              'marginLeft': '10px'
            }}>
              <b> ON </b>
            </p>
            :
            <p style={{
              "fontSize": "10px",
              "marginBottom": "0px",
              "width": '32px',
              "background": '#FA6157',
              "color": '#ffffff',
              'paddingTop': '1px',
              'paddingBottom': '1px',
              'borderRadius': '5px',
              'marginLeft': '10px'
            }}>
              <b> OFF </b>
            </p>
          }
        </div>
      </div>
      {data &&
        <Col>
          {sideBar.configs.minHumidity < data.humidity && sideBar.configs.maxHumidity > data.humidity
            ? <div className='side-card'>
              <p style={{ "fontSize": "11px", "marginBottom": "0px" }}>Umidade</p>
              {isNaN(parseFloat(data.humidity))
                ? <p style={{ "fontSize": "11px", "fontWeight": "500" }}>Sem dados!</p>
                : <p style={{ "fontSize": "17px", "fontWeight": "500" }}> {parseFloat(data.humidity).toFixed(1) + ' %'}</p>
              }
            </div>
            : <div className='side-card' style={{ "background": "#FA6157" }}>
              <p style={{ "fontSize": "11px", "marginBottom": "0px" }}>Umidade</p>
              {isNaN(parseFloat(data.humidity))
                ? <p style={{ "fontSize": "11px", "fontWeight": "500" }}>Sem dados!</p>
                : <p style={{ "fontSize": "17px", "fontWeight": "500" }}> {parseFloat(data.humidity).toFixed(1) + ' %'}</p>
              }
            </div>
          }
        </Col>
      }
      {data &&
        <Col>
          {sideBar.configs.minTemperature < data.temperature && sideBar.configs.maxTemperature > data.temperature
            ? <div className='side-card'>
              <p style={{ "fontSize": "11px", "marginBottom": "0px" }}>Temperatura</p>
              {isNaN(parseFloat(data.temperature))
                ? <p style={{ "fontSize": "11px", "fontWeight": "500" }}>Sem dados!</p>
                : <p style={{ "fontSize": "17px", "fontWeight": "500" }}> {parseFloat(data.temperature).toFixed(1) + ' °C'}</p>
              }
            </div>
            : <div className='side-card' style={{ "background": "#FA6157" }}>
              <p style={{ "fontSize": "11px", "marginBottom": "0px" }}>Temperatura</p>
              {isNaN(parseFloat(data.temperature))
                ? <p style={{ "fontSize": "11px", "fontWeight": "500" }}>Sem dados!</p>
                : <p style={{ "fontSize": "17px", "fontWeight": "500" }}> {parseFloat(data.temperature).toFixed(1) + ' °C'}</p>
              }
            </div>
          }
        </Col>
      }
      {data &&
        <Col>
          {sideBar.configs.minPH < data.ph && sideBar.configs.maxPH > data.ph
            ? <div className='side-card'>
              <p style={{ "fontSize": "11px", "marginBottom": "0px" }}>PH</p>
              {isNaN(parseFloat(data.ph))
                ? <p style={{ "fontSize": "11px", "fontWeight": "500" }}>Sem dados!</p>
                : <p style={{ "fontSize": "17px", "fontWeight": "500" }}> {parseFloat(data.ph).toFixed(0)}</p>
              }
            </div>
            : <div className='side-card' style={{ "background": "#FA6157" }}>
              <p style={{ "fontSize": "11px", "marginBottom": "0px" }}>PH</p>
              {isNaN(parseFloat(data.ph))
                ? <p style={{ "fontSize": "11px", "fontWeight": "500" }}>Sem dados!</p>
                : <p style={{ "fontSize": "17px", "fontWeight": "500" }}> {parseFloat(data.ph).toFixed(0)}</p>
              }
            </div>
          }
        </Col>
      }
    </Row>
  )
}
export default Card
