import React, { useState, useEffect } from "react";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import * as C from "./style";
import { useNavigate } from "react-router-dom";
import Api from "../../services/Api";

const Signin = () => {
  const navigate = useNavigate();
  const [id, setId] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async () => {
    if (!id) {
      setError("Preencha o ID");
      return;
    }
    if (id === "919Eng999") {
      try {
        const data = await Api.get(`/empresas/listempresas`, { "headers": { 'x-access-token': process.env.REACT_APP_API_KEY } })
        if (data.status === 200) {
          localStorage.setItem('empresas', JSON.stringify(data.data))
          navigate("/medidas");
        } else {
          setError('Erro ao carregar.')
        }
      } catch (err) {
        setError('Erro ao carregar.')
      }
    } else {
      try {
        const empresa = await Api.get(`/empresas/listempresasId/${id}`)
        if (empresa.status === 200) {
          localStorage.setItem('empresa', JSON.stringify(empresa.data))
          navigate("/dashboard");
        }
      } catch (err) {
        setError('ID inválido.')
      }
    }
  }

  useEffect(() => {
    const empresaLocal = JSON.parse(localStorage.getItem('empresa'))
    const empresasLocal = JSON.parse(localStorage.getItem('empresas'))
    if (empresaLocal) {
      navigate("/dashboard");
    } else if (empresasLocal) {
      navigate("/medidas");
    } else {
      navigate("/");
    }
  }, [])

  return (
    <C.ContainerSignin>
      <C.Container>
        <C.Content>
          <C.Label>Login</C.Label>
          <Input
            type="text"
            placeholder="Digite o ID da Empresa"
            value={id}
            onChange={(e) => [setId(e.target.value), setError('')]}
          />
          <C.labelError>{error}</C.labelError>
          <Button Text="Entrar" onClick={handleLogin} />
        </C.Content>
      </C.Container>
    </C.ContainerSignin>
  );
};
export default Signin;