import React from 'react';
import Search from './components/Search/Search';

const SearchBar = (props) => {
  const { onSearch } = props;

  return (
    <Search
      onSearch={(e) => {
        onSearch(e.target.value)
      }}
    />
  );
};
export default SearchBar;
