import styled from "styled-components";

export const Button = styled.button`
  padding: 16px 20px;
  outline: none;
  border: none;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
  background-color: rgba(28,66,32, 0.6);
  color: white;
  font-weight: 600;
  font-size: 16px;
  max-width: 350px;
  &:hover{
    background: rgba(28,66,32);
  }
`;