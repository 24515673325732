import React, { useEffect, useState } from "react";
import { ProgressBar, Container, Button } from 'react-bootstrap';
import Graficos from "../graficos/graficos";
import Api from '../../services/Api';

const Dashboard = () => {

  const empresaId = JSON.parse(localStorage.getItem('empresa'))
  const instruments = JSON.parse(localStorage.getItem('instruments'))

  const token = empresaId.token

  const [progress, setProgress] = useState(0);
  const [empresa] = useState(empresaId._id);

  const getEmpresa = async () => {
    if (empresa) {
      try {
        const data = await Api.get(`/empresas/listempresas/${empresa}`, { "headers": { 'x-access-token': token } });
        if (data.status === 200) {
          localStorage.setItem('empresa', JSON.stringify(data.data))
        }
      } catch (error) {
        console.log("Ocorreu um erro ao buscar a empresa!");
      }
    }
  }

  const getEquipamentos = async () => {
    if (empresa) {
      try {
        const instruments = await Api.get(`/instruments/company/${empresa}`, { "headers": { 'x-access-token': token } });
        if (instruments.status === 200) {
          localStorage.setItem('instruments', JSON.stringify(instruments.data))
        }
      } catch (error) {
        console.log("Ocorreu um erro ao buscar a empresa");
      }
    }
  }

  const refresh = () => {
    window.location.reload(true)
  }

  useEffect(() => {
    getEmpresa();
    getEquipamentos();
    const intervalId = setInterval(() => {
      getEmpresa();
      getEquipamentos();
      refresh();
    }, 3600000)
    return () => clearInterval(intervalId);
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((prevProgress) => {
        const nextProgress = prevProgress + 1;
        if (nextProgress === 100) {
          return 0;
        }
        return nextProgress;
      });
    }, 295);
    return () => clearInterval(intervalId);
  }, [progress]);

  const handleSair = () => {
    const respostaConfirm = window.confirm('Tem certeza que deseja sair?');
    if (respostaConfirm) {
      localStorage.clear();
      window.location.href = "/"
    }
  }

  return (
    <Container fluid className="mt-1 mb-1">
      <Button onClick={() => { handleSair() }} variant="outline-danger" size="sm">Sair</Button>
      <ProgressBar className="mt-1 mb-1" variant="success" now={progress} label={`${progress}%`} visuallyHidden />
      {instruments && <Graficos setProgress={setProgress} progress={progress} />}
    </Container>
  )
}
export default Dashboard
