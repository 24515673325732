import React, { useEffect, useState } from "react";
import List from "./components/List";
import Api from '../../services/Api';

const Sensores = () => {
  const empresaId = JSON.parse(localStorage.getItem('empresa'))
  const token = empresaId.token

  const [data, setData] = useState()
  const [instrumentos, setInstrumentos] = useState()
  const [empresa] = useState(empresaId._id)

  const getEmpresa = async () => {
    if (empresa) {
      try {
        const { data } = await Api.get(`/empresas/listempresas/${empresa}`, { headers: { 'x-access-token': token } });
        setData(data);
      } catch (error) {
        console.log("Ocorreu um erro ao buscar a empresa");
      }
    }
  }

  const getEquipamentos = async () => {
    if (empresa) {
      try {
        const instruments = await Api.get(`/instruments/company/${empresa}`, { "headers": { 'x-access-token': token } });
        setInstrumentos(instruments.data)
      } catch (error) {
        console.log("Ocorreu um erro ao buscar a empresa");
      }
    }
  }

  useEffect(() => {
    getEquipamentos();
    getEmpresa();
    setInterval(() => { getEquipamentos() }, 600000)
  }, [])

  return (
    <div className="contain-img">
      <div className="contain">
        {data && instrumentos &&
          <List data={data} instrumentos={instrumentos} />
        }
      </div>
    </div>
  )
}
export default Sensores
